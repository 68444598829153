import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { DEFAULT_ERROR } from '../constants/errors';
import http from '../services/Http';
import { ACTION_TYPES } from '../constants';

export const actionCreator = ({
  id, route, toastSuccess, actionType,
}) => createAsyncThunk(
  id,
  async (actionParams, { rejectWithValue }) => {
    const { callback, ...params } = actionParams || {};

    try {
      const result = await http[actionType](
        route,
        [ACTION_TYPES.get, ACTION_TYPES.delete].includes(actionType) ? { params } : params,
      );

      if (toastSuccess) {
        toast.success(toastSuccess);
      }

      if (callback) {
        callback();
      }

      return result;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }

      return rejectWithValue(null);
    }
  },
);
