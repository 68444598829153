import { createSlice } from '@reduxjs/toolkit';
import { actionCreator } from '../utility/action';
import { ACTION_TYPES } from '../constants';

const impressionSiteKey = 'impressionSites';
const conversionSiteKey = 'conversionSites';
const impressionSegmentKey = 'impressionSegments';
const conversionSegmentKey = 'conversionSegments';
const audienceActivationSegmentKey = 'audienceActivationSegments';

const segmentsTypeMap = {
  16: impressionSegmentKey,
  17: conversionSegmentKey,
  18: audienceActivationSegmentKey,
};

const initialState = {
  partners: [],
  customers: [],
  devices: [],
  aASegments: [],
  [impressionSegmentKey]: [],
  [conversionSegmentKey]: [],
  [audienceActivationSegmentKey]: [],
  [impressionSiteKey]: [],
  [conversionSiteKey]: [],
};

export const getAudienceActivationSegments = actionCreator({
  id: 'shared/audience-activation-segments',
  route: '/audience-activation-segments',
  actionType: ACTION_TYPES.get,
});

export const getPartners = actionCreator({
  id: 'shared/partners',
  route: '/partners',
  actionType: ACTION_TYPES.get,
});

export const getCustomers = actionCreator({
  id: 'shared/customers',
  route: '/customers',
  actionType: ACTION_TYPES.get,
});

export const getSegments = actionCreator({
  id: 'shared/segments',
  route: '/shared-segments',
  actionType: ACTION_TYPES.get,
});

export const getDevices = actionCreator({
  id: 'shared/devices',
  route: '/devices',
  actionType: ACTION_TYPES.get,
});

export const getSites = actionCreator({
  id: 'shared/sites',
  route: '/sites',
  actionType: ACTION_TYPES.get,
});

export const sharedSlice = createSlice({
  name: 'shared',
  reducers: {
    setPartners(state, payload) {
      state.partners = payload;
    },
    setCustomers(state, payload) {
      state.customers = payload;
    },
    setImpressionSites(state, payload) {
      state.impressionSites = payload;
    },
    setConversionSites(state, payload) {
      state.conversionSites = payload;
    },
    setImpressionSegments(state, payload) {
      state.impressionSegments = payload;
    },
    setConversionSegments(state, payload) {
      state.conversionSegments = payload;
    },
    setDevices(state, payload) {
      state.devices = payload;
    },
    addNewAudienceActivationSegment(state, { payload }) {
      state.aASegments = [
        ...state.aASegments,
        payload,
      ];
    },
    addNewSegment(state, { payload }) {
      state[segmentsTypeMap[payload.type_id]] = [
        ...state[segmentsTypeMap[payload.type_id]],
        payload,
      ];
    },
    resetBaseReducer: () => initialState,
  },
  initialState,
  extraReducers: {
    [getPartners.fulfilled]: (state, { payload }) => {
      state.partners = payload.data;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload.data;
    },
    [getAudienceActivationSegments.fulfilled]: (state, { payload }) => {
      state.aASegments = payload.data;
    },
    [getDevices.fulfilled]: (state, { payload }) => {
      state.devices = payload.data;
    },
    [getSegments.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[segmentsTypeMap[arg.attribute_type_id]] = payload.data;
    },
    [getSites.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg?.isImpression ? impressionSiteKey : conversionSiteKey] = payload.data;
    },
  },
});

export const {
  resetBaseReducer,
  setConversionSegments,
  setImpressionSegments,
  setConversionSites,
  setImpressionSites,
  setDevices,
  setCustomers,
  setPartners,
  addNewSegment,
  addNewAudienceActivationSegment,
} = sharedSlice.actions;
export default sharedSlice.reducer;
