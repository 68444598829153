import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { ACTION_TYPES, tableDefaultState } from '../constants';
import { handleActionError } from '../utility/Utils';
import { skin, SKINS } from '../constants/skin';
import { actionCreator } from '../utility/action';

const initialState = {
  tagsTable: tableDefaultState,
  isTagExportLoading: false,
};

export const getTags = createAsyncThunk(
  '/tags',
  async ({ filters, ...params }, { rejectWithValue }) => {
    try {
      if (!filters.partner_customer_id || !filters.partner_id) return [];

      const url = skin === SKINS.DATONICS ? '/audiences-page-datonics' : '/tags';
      const res = await http.get(url, { params: { ...params, ...filters } });

      return res.data;
    } catch (e) {
      handleActionError({ e, rejectWithValue });
    }
  },
);

export const createTag = createAsyncThunk(
  '/campaign/tag/create',
  async ({
    data, callback, partner_id, customer_id,
  }, { rejectWithValue }) => {
    try {
      const url = skin === SKINS.DATONICS ? '/audiences-page-datonics' : '/tags';
      const res = await http.post(`${url}/${partner_id}/${customer_id}`, data);
      callback();

      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      }
      return rejectWithValue('error');
    }
  },
);

export const deleteTag = actionCreator({
  id: '/tag/delete',
  route: skin === SKINS.DATONICS ? '/audiences-page-datonics' : '/tags',
  actionType: ACTION_TYPES.delete,
});

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    clearTagsTable: (state) => {
      state.tagsTable = tableDefaultState;
    },
  },
  extraReducers: {
    [getTags.pending]: (state) => {
      state.tagsTable.loading = true;
    },
    [getTags.fulfilled]: (state, { payload, meta: { arg } }) => {
      if (payload?.data) {
        state.tagsTable = {
          ...state.tagsTable,
          data: payload.data,
          total: payload.pagination.total || state.tagsTable.total,
          page: arg.page,
        };
      }

      state.tagsTable.loading = false;
    },
    [getTags.rejected]: (state) => {
      state.tagsTable.loading = false;
    },
    [deleteTag.pending]: (state) => {
      state.isEditLoading = true;
    },
    [deleteTag.fulfilled]: (state) => {
      state.isEditLoading = false;
      toast.success('Tags deleted');
    },
    [createTag.pending]: (state) => {
      state.isEditLoading = true;
    },
    [createTag.rejected]: (state) => {
      state.isEditLoading = false;
    },
    [createTag.fulfilled]: (state, { payload }) => {
      state.isEditLoading = false;
      const errors = payload?.data?.errors;
      if (errors?.length) {
        errors.forEach((message) => toast.error(message, {
          duration: 10000,
        }));
      } else {
        toast.success('Tags created');
      }
    },
  },
});

export const {
  clearTagsTable,
} = tagsSlice.actions;

export default tagsSlice.reducer;
