import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants/formats';
import { EXPORT_KEYS_RAW_REPORT } from '../constants/rawReport';
import http from '../services/Http';
import { exportToCsv } from '../utility';

const initialState = {
  loading: false,
  isTotalLoading: false,
  isTableLoading: false,
  isSvgLoading: false,
  data: [],
  page: 1,
  total: 0,
  totals: {},
};

export const getRawReport = createAsyncThunk(
  'raw-report',
  async ({ filters, ...params }) => {
    const { startDate, endDate, date } = filters;

    const { data } = await http.get('/raw-report', {
      params: {
        ...params,
        ...filters,
        start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
        date: startDate ? null : date,
      },
    });

    return data;
  },
);

export const getRawReportCsv = createAsyncThunk(
  'raw-report/csv',
  async ({ filters, columns, ...params }) => {
    const { startDate, endDate, date } = filters;

    const { data } = await http.get('/raw-report/csv', {
      params: {
        ...params,
        ...filters,
        start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
        date: startDate ? null : date,
      },
    });

    const keys = [
      ...EXPORT_KEYS_RAW_REPORT.filter((key) => columns.includes(key.field)),
      ...(filters.imperssion_category_id?.length ? [{ title: 'Impression Segment', field: 'patv_label_Imp' }] : []),
      ...(filters.action_category_id?.length ? [{ title: 'Conversion Segment', field: 'patv_label_Act' }] : []),
      ...(filters.impression_adserver_dtype?.length ? [{ title: 'Impression Device', field: 'dtype_label_Imp' }] : []),
      ...(filters.action_adserver_dtype?.length ? [{ title: 'Conversion Device', field: 'dtype_label_Act' }] : []),
      ...(filters.impression_site_id?.length ? [{ title: 'Impression Site', field: 'dpsite_imp_url' }] : []),
      ...(filters.action_site_id?.length ? [{ title: 'Conversion Site', field: 'dpsite_act_url' }] : []),
    ];

    exportToCsv({
      data,
      name: 'rawReport.csv',
      keys,
    });
    return data;
  },
);

export const getPerformanceTotal = createAsyncThunk(
  '/raw-report/total',
  async (params) => {
    const { data } = await http.get('/getPerformanceTotal/performance/totals', { params });

    return data;
  },
);

export const rawReportSlice = createSlice({
  name: 'rawReport',
  reducers: {
    clearRawReportReducer: () => initialState,
    setRawReportCustomers: (state, { payload }) => {
      state.customers = payload;
    },
  },
  extraReducers: {
    [getPerformanceTotal.pending]: (state) => {
      state.loading = true;
    },
    [getPerformanceTotal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.totals = payload?.[0];
    },
    [getRawReportCsv.pending]: (state) => {
      state.isSvgLoading = true;
    },
    [getRawReportCsv.fulfilled]: (state) => {
      state.isSvgLoading = false;
    },
    [getRawReportCsv.rejected]: (state) => {
      state.isSvgLoading = false;
    },
    [getRawReport.pending]: (state, { meta: { arg } }) => {
      if (arg?.filters?.isTotal) {
        state.isTotalLoading = true;
      } else {
        state.loading = true;
      }
    },
    [getRawReport.fulfilled]: (state, { payload, meta: { arg } }) => {
      if (arg?.filters?.isTotal) {
        state.totals = payload.data[0];
        state.isTotalLoading = false;
      } else {
        state.data = payload.data;
        state.total = payload.pagination?.total === undefined
          ? state.total
          : payload.pagination?.total;
      }
      state.loading = false;
    },
  },
  initialState,
});

export const { clearRawReportReducer, setRawReportCustomers } = rawReportSlice.actions;
export default rawReportSlice.reducer;
