import { SvgIcon } from '@mui/material';
import React from 'react';

function CheckboxIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '12px', ...sx }} width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <rect x="0.25" y="0.25" width="11.5" height="11.5" rx="1.75" stroke="#108AE2" strokeWidth="0.5" fill="#fff" />
      <rect x="2" y="2" width="8" height="8" rx="1" fill="primary.main" />
    </SvgIcon>
  );
}

export default CheckboxIcon;
