import {
  AudienceIcon,
  DashboardIcon,
  EbookIcon,
  HistoryIcon,
  InsightIcon,
  ManageTagsIcon,
  ManageUsersIcon,
  OfflineDataNavbar,
  RawReportIcon,
  SegmentsIcon,
  DealIcon,
} from '../../assets/svg';
import { USER_TYPE_DATONICS, USER_TYPE_IIQ } from '../../constants/users';

export const rolesPages = {
  'dashboard.can_read': '/dashboard',
  'tags.can_read': '/dashboard/campaign/tag-management',
  'performance.can_read': '/performance',
  'raw_report.can_read': '/raw-report',
  'insights.can_read': '/insight-history',
  'file_upload.can_read': '/offline-data/upload-offline-data',
  'roles.can_read': '/users-permissions',
  'users.can_read': '/users',
  'insights_datonics.can_read': '/insight-history',
  'audiences_page_datonics.can_read': '/dashboard/audience',
  'users_datonics.can_read': '/users',
  'file_tracker_datonics.can_read': '/offline-data',
  'datonics_file_upload.can_read': '/records-uploder',
};

export default [
  {
    id: 'dashboard',
    role: 'dashboard.can_read',
    title: 'Dashboard',
    headerTitle: 'Dashboard',
    icon: DashboardIcon,
    navLink: '/dashboard',
    userType: [USER_TYPE_IIQ],
  },
  {
    id: 'reports',
    title: 'Attribution Reports',
    roles: ['performance.can_read', 'raw_report.can_read'],
    icon: RawReportIcon,
    userType: [USER_TYPE_IIQ],
    list: [
      {
        id: 'performanceReport',
        role: 'performance.can_read',
        title: 'Attribution Report',
        headerTitle: 'Attribution Report',
        navLink: '/performance',
      },
      {
        id: 'raw-report',
        role: 'raw_report.can_read',
        headerTitle: 'Raw Attribution Report ',
        title: 'Raw Attribution Report ',
        navLink: '/raw-report',
      },
    ],
  },
  {
    id: 'manage-tags',
    role: 'tags.can_read',
    headerTitle: 'Tags Management',
    icon: ManageTagsIcon,
    title: 'Tags Management',
    navLink: '/dashboard/campaign/tag-management',
    userType: [USER_TYPE_IIQ],
  },
  {
    id: 'offline-data',
    title: 'CRM Data Onboarding',
    icon: OfflineDataNavbar,
    roles: ['file_upload.can_read', 'file_tracker.can_read'],
    svgSx: { path: { stroke: 'none !important' } },
    userType: [USER_TYPE_IIQ],
    list: [
      {
        id: 'file-uploader',
        role: 'file_upload.can_read',
        title: 'CRM Data onboard',
        headerTitle: 'CRM Data onboard',
        navLink: '/offline-data/upload-offline-data',
      },
      {
        id: 'my-files',
        title: 'Onboarding Health',
        headerTitle: 'Onboarding Health',
        role: 'file_tracker.can_read',
        navLink: '/offline-data',
      },
    ],
  },
  {
    id: 'get-insight',
    role: 'insights.can_read',
    headerTitle: 'Audience Insights Report',
    title: 'Audience Insights',
    navLink: '/insight-history',
    icon: InsightIcon,
    userType: [USER_TYPE_IIQ],
  },
  {
    id: 'dealCuration',
    title: 'Deal Curation',
    roles: ['segments.can_read', 'audiences.can_read', 'deals.can_read'],
    icon: DealIcon,
    userType: [USER_TYPE_IIQ],
    list: [
      {
        id: 'segment',
        role: 'segments.can_read',
        title: 'Segments',
        headerTitle: 'My Segments',
        icon: SegmentsIcon,
        navLink: '/segment',
        userType: [USER_TYPE_IIQ],
      },
      {
        id: 'audience',
        role: 'audiences.can_read',
        title: 'Audiences',
        headerTitle: 'Audiences',
        icon: AudienceIcon,
        navLink: '/audience',
        userType: [USER_TYPE_IIQ],
      },
      {
        id: 'deals',
        role: 'deals.can_read',
        title: 'Curated Deals',
        headerTitle: 'Curated Deals',
        icon: DealIcon,
        navLink: '/deals',
        userType: [USER_TYPE_IIQ],
      },
    ],
  },
  {
    id: 'insights',
    role: 'insights_datonics.can_read',
    headerTitle: 'Reports',
    icon: InsightIcon,
    title: 'Reports',
    navLink: '/insight-history',
    userType: [USER_TYPE_DATONICS],
  },
  {
    id: 'audience-segments',
    role: 'audiences_page_datonics.can_read',
    headerTitle: 'Pixel Management',
    icon: ManageTagsIcon,
    title: 'Pixel Management',
    navLink: '/dashboard/audience',
    userType: [USER_TYPE_DATONICS],
  },
  {
    id: 'offline-data',
    title: 'Records uploader',
    headerTitle: 'Upload Customer Records',
    icon: OfflineDataNavbar,
    roles: 'datonics_file_upload.can_read',
    svgSx: { path: { stroke: 'none !important' } },
    navLink: '/records-uploder',
    userType: [USER_TYPE_DATONICS],
  },
  {
    id: 'Files tracker',
    title: 'Files Tracker',
    role: 'file_tracker_datonics.can_read',
    headerTitle: 'Files Tracker',
    icon: OfflineDataNavbar,
    navLink: '/offline-data',
    userType: [USER_TYPE_DATONICS],
  },
  {
    id: 'history',
    title: 'History',
    role: 'history.can_read',
    headerTitle: 'History',
    icon: HistoryIcon,
    navLink: '/history',
    userType: [USER_TYPE_IIQ],
  },
  {
    id: 'eBook',
    role: 'e_book.can_read',
    title: 'E-Book',
    headerTitle: 'E-Book',
    userType: [USER_TYPE_IIQ],
    icon: EbookIcon,
    link: 'https://intentiq.gitbook.io/iiq-cda/',
    svgSx: { left: '28px' },
  },
  {
    id: 'users-management',
    title: 'Users Management',
    roles: ['users.can_read', 'roles.can_read'],
    icon: ManageUsersIcon,
    userType: [USER_TYPE_IIQ],
    list: [
      {
        id: 'permission',
        role: 'roles.can_read',
        title: 'Permissions',
        headerTitle: 'Users permissions',
        navLink: '/users-permissions',
      },
      {
        id: 'users',
        title: 'Users',
        role: 'users.can_read',
        headerTitle: 'Users',
        navLink: '/users',
      },
    ],
  },
  {
    id: 'users',
    title: 'Users',
    roles: ['users_datonics.can_read'],
    icon: ManageUsersIcon,
    headerTitle: 'Users',
    userType: [USER_TYPE_DATONICS],
    navLink: '/users',
  },
  {
    id: 'data-mapping',
    role: 'data_mapping.can_read',
    title: 'Data Mapping',
    headerTitle: 'Data Mapping',
    icon: DealIcon,
    navLink: '/data-mapping',
    userType: [USER_TYPE_IIQ],
  },
];
