import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import http from '../services/Http';
import { exportToCsv } from '../utility';
import { EXPORT_KEYS_INSIGHTS } from '../constants/insights';
import {
  toastSuccess,
} from '../utility/toast';
import { TITLES } from '../views/Insights/constants';
import { actionCreator } from '../utility/action';
import { ACTION_TYPES } from '../constants';
import { DATE_FORMAT_PRIMARY } from '../constants/formats';
import { HOUSEHOLDS, ONE_PERSON } from '../views/Insights/Charts/BarChart/constants';
import { skin, SKINS } from '../constants/skin';

const initialState = {
  data: {},
  job: {},
  charts: [],
  dashboardCharts: [],
  chartsList: [],
  segments: [],
  dashboards: [],
  detailsPageSegmentsLoading: false,
  detailsPageLoading: false,
  isChartsLoading: false,
  segmentsTable: {
    data: [],
    loading: false,
    total: 0,
    page: 1,
    order_by: 'composition',
    order_dir: 'DESC',
  },
};

export const getInsights = createAsyncThunk(
  'insights',
  async ({ filters, page, perPage }) => {
    if (filters.customer_id) {
      const url = skin === SKINS.DATONICS ? '/insights-datonics' : '/insights';

      const { data } = await http.get(url, {
        params: {
          ...filters,
          page,
          perPage,
        },
      });

      return data;
    }

    return {};
  },
);

export const deleteInsights = createAsyncThunk(
  'insights/delete',
  async ({ refreshInsights, id }) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics' : '/insights';
    const { data } = await http.delete(`${url}/${id}`);
    refreshInsights();
    return data;
  },
);

export const getInsightsJob = actionCreator({
  id: 'insights/job',
  route: skin === SKINS.DATONICS ? '/insights-datonics/job' : 'insights/job',
  actionType: ACTION_TYPES.get,
});

export const createInsight = actionCreator({
  id: 'insights/create',
  route: skin === SKINS.DATONICS ? 'insights-datonics' : 'insights',
  actionType: ACTION_TYPES.post,
});

export const changeIsinternal = createAsyncThunk(
  'insights/create',
  async ({ refreshInsights, id }) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/is_internal' : '/insights/is_internal';

    const { data } = await http.put(`${url}/${id}`);
    refreshInsights();
    return data;
  },
);

export const sendGetSimilarRequest = createAsyncThunk(
  'insights/send-get-similar-request',
  async (params) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/send-get-similar-request' : '/insights/send-get-similar-request';
    const { data } = await http.post(url, params);
    toastSuccess('Successfully Sent!');
    return data;
  },
);

export const getInsightsCharts = createAsyncThunk(
  'insights/charts',
  async (params) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/charts' : '/insights/charts';
    const { data } = await http.get(url, { params });

    return data;
  },
);

export const getInsightsChartsList = createAsyncThunk(
  'insights/charts/list',
  async ({ filters, ...params }) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/charts/list' : '/insights/charts/list';
    const { data } = await http.get(url, {
      params: {
        ...params,
        ...filters,
      },
    });

    return data;
  },
);

export const getInsightsChartsCsv = createAsyncThunk(
  'insights/charts/csv',
  async (params, { getState }) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/charts' : '/insights/charts';
    const state = getState();
    const { data } = await http.get(url, { params: { ...params, isExport: true } });
    const job = state.insights.job;
    const impressionSegment = state.homeData.impressionSegment;
    const conversionSegment = state.homeData.conversionSegment;

    const resData = data
      .filter((item) => item.type === ONE_PERSON || item.type === HOUSEHOLDS)
      .map((item) => ({
        ...item,
        composition: `${item.composition}%`,
        type: TITLES[item.type],
        persons: '',
        households: '',
        report_id: '',
        creation_log: '',
        date_range: '',
        audience: '',
      }));

    const startDateUTC = moment.utc(job.query_start_date);
    const endDateUTC = moment.utc(job.query_end_date || job.lambda_end_date0);
    const dateRange = `${startDateUTC.format(DATE_FORMAT_PRIMARY)} - ${endDateUTC.format(DATE_FORMAT_PRIMARY)}`;

    const impSegment = job.imperssion_category_id
      ? job.imperssion_category_id?.split(',').map((id) => {
        return impressionSegment?.find((item) => item.patv_id === +id)?.patv_label;
      })
      : [];
    const conSegment = job.action_category_id
      ? job.action_category_id?.split(',').map((id) => {
        return conversionSegment?.find((item) => item.patv_id === +id)?.patv_label;
      })
      : [];
    const audience = `${[...impSegment, ...conSegment].toString(' ')}`;

    resData.unshift({
      chart_name: '',
      label: '',
      segment_id: '',
      segment_full_name: '',
      total: '',
      expected: '',
      composition: '',
      index_value: '',
      type: '',
      persons: job.dis_1,
      households: job.dis_0,
      report_id: job.id,
      creation_log: moment(job.create_date).format(DATE_FORMAT_PRIMARY),
      date_range: dateRange,
      audience,
    });

    const {
      partner_name, customerName, report_name, id,
    } = job;
    const reportName = report_name ? `_${report_name}` : '';

    exportToCsv({
      name: `${partner_name}_${customerName}${reportName}_${id}.csv`,
      data: resData,
      keys: EXPORT_KEYS_INSIGHTS,
    });
    return data;
  },
);

export const getInsightsSegments = createAsyncThunk(
  'insights/segments',
  async (params) => {
    const url = skin === SKINS.DATONICS ? '/insights-datonics/segments' : '/insights/segments';
    const { data } = await http.get(url, { params });

    return data;
  },
);

export const getInsightDashboards = actionCreator({
  id: '/insights/dashboards',
  route: skin === SKINS.DATONICS ? '/insights-datonics/dashboards' : '/insights/dashboards',
  actionType: ACTION_TYPES.get,
});

export const getDashboardsCharts = actionCreator({
  id: '/insights/dashboards/charts',
  route: skin === SKINS.DATONICS ? 'insights-datonics/dashboards/charts' : '/insights/dashboards/charts',
  actionType: ACTION_TYPES.get,
});

export const insightsSlice = createSlice({
  name: 'insights',
  extraReducers: {
    [getDashboardsCharts.pending]: (state) => {
      state.isChartsLoading = true;
    },
    [getDashboardsCharts.rejected]: (state) => {
      state.isChartsLoading = false;
    },
    [getDashboardsCharts.fulfilled]: (state, { payload }) => {
      state.dashboardCharts = payload.data;
      state.isChartsLoading = false;
    },
    [getInsightDashboards.pending]: (state) => {
      state.loading = true;
    },
    [getInsightDashboards.rejected]: (state) => {
      state.loading = false;
    },
    [getInsightDashboards.fulfilled]: (state, { payload }) => {
      state.dashboards = payload.data;
      state.loading = false;
    },
    [getInsights.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsJob.pending]: (state) => {
      state.detailsPageLoading = true;
    },
    [getInsightsJob.fulfilled]: (state, { payload }) => {
      state.detailsPageLoading = false;
      state.job = payload.data;
    },
    [getInsightsJob.rejected]: (state) => {
      state.detailsPageLoading = false;
    },
    [getInsightsChartsList.pending]: (state) => {
      state.segmentsTable.loading = true;
    },
    [getInsightsChartsList.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.segmentsTable = {
        ...state.segmentsTable,
        loading: false,
        data: payload.data,
        total: payload.pagination.total || state.segmentsTable.total,
        order_by: arg.filters.order_by,
        order_dir: arg.filters.order_dir,
      };
    },
    [getInsights.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getInsightsChartsCsv.pending]: (state) => {
      state.loading = true;
      state.svgLoading = true;
    },
    [getInsightsChartsCsv.fulfilled]: (state) => {
      state.loading = false;
      state.svgLoading = false;
    },
    [getInsightsChartsCsv.rejected]: (state) => {
      state.loading = false;
      state.svgLoading = false;
    },
    [getInsightsSegments.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsSegments.fulfilled]: (state, { payload }) => {
      state.segments = payload;
      state.loading = false;
    },
    [getInsightsCharts.pending]: (state) => {
      state.isChartsLoading = true;
    },
    [getInsightsCharts.rejected]: (state) => {
      state.isChartsLoading = false;
    },
    [createInsight.pending]: (state) => {
      state.loading = true;
    },
    [createInsight.rejected]: (state) => {
      state.loading = false;
    },
    [createInsight.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInsightsCharts.fulfilled]: (state, { payload }) => {
      state.charts = payload;
      state.isChartsLoading = false;
    },
  },
  reducers: {
    resetInsightsReducer: () => initialState,
    clearInsights: (state) => {
      state.data = [];
    },
  },
  initialState,
});

export const { resetInsightsReducer, clearInsights } = insightsSlice.actions;
export default insightsSlice.reducer;
