import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';
import http from '../services/Http';
import {
  toastError, toastErrorDatonics, toastSuccess, toastSuccessDatonics,
} from '../utility/toast';
import { exportToCsv } from '../utility';
import { EXPORT_KEYS_FILE_TRACKER } from '../constants/offline-data';
import { USER_TYPE_DATONICS } from '../constants/users';
import { DATE_TIME_FORMAT } from '../constants/formats';
import { skin, SKINS } from '../constants/skin';

const initialState = {
  formats: [],
  data: [],
  formatDetails: [],
  newFormat: null,
  isCreateFormatLoading: false,
  fileUploadLoading: false,
  fileName: null,
  fileError: false,
  tableLoading: false,
  total: 0,
  page: 1,
};

export const getFormats = createAsyncThunk(
  'offline-data/formats',
  async (params, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const res = await http.get('offline-data/formats', { params });

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage);
      }
    }
  },
);

export const createFormatDataMapping = createAsyncThunk(
  'offline-data/create-format-dataMapping',
  async (values, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const res = await http.post('offline-data/create-format', values);

      const message = 'New format was successfully saved! You can now upload your file';
      if (user?.user_type === USER_TYPE_DATONICS) toastSuccessDatonics(message);
      else toast.success(message);

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toast.error(errorMessage);
      }
    }
  },
);

export const createFormat = createAsyncThunk(
  'offline-data/create-format',
  async (values, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const url = skin === SKINS.DATONICS ? 'datonics-create-format' : 'offline-data/create-format';
      const res = await http.post(url, values);

      const message = 'New format was successfully saved! You can now upload your file';
      if (user?.user_type === USER_TYPE_DATONICS) toastSuccessDatonics(message);
      else toastSuccess(message);

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage, { position: 'bottom-right' });
      }
    }
  },
);

export const uploadFile = createAsyncThunk(
  'offline-data/upload',
  async (values, { rejectWithValue, getState }) => {
    const { auth: { user } } = getState();

    try {
      const url = skin === SKINS.DATONICS ? 'datonics-file-upload' : 'offline-data/upload';

      const res = await http.post(url, values);

      if (res.data?.message) {
        if (user?.user_type === USER_TYPE_DATONICS) {
          if (res.data?.error) toastError(res.data.message, { position: 'bottom-right' });
          else toastSuccessDatonics(res.data.message);
        } else toastError(res.data.message, { position: 'bottom-right' });

        return rejectWithValue('error');
      }

      return res.data?.filename;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage);
      }
      return rejectWithValue('error');
    }
  },
);

export const getFormatDetails = createAsyncThunk(
  'offline-data/format-details',
  async (params, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const res = await http.get('offline-data/format-details', { params });

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage, { position: 'bottom-right' });
      }
    }
  },
);

export const getFiles = createAsyncThunk(
  'offline-data/files',
  async ({ filters, ...params }, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const url = skin === SKINS.DATONICS ? 'file-tracker-datonics' : 'file-tracker';
      const res = await http.get(url, { params: { ...params, ...filters } });

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage);
      }
    }
  },
);

export const downloadFiles = createAsyncThunk(
  'offline-data/files-csv',
  async (params, { getState }) => {
    const { auth: { user } } = getState();

    try {
      const url = skin === SKINS.DATONICS ? 'file-tracker-datonics/csv' : 'file-tracker/csv';
      const res = await http.get(url, { params });
      const timezone = moment.tz.guess();

      const data = res.data.map((file) => {
        const fileWIthInfo = { ...file, ...file.additional_info };
        const item = {};

        Object.keys(fileWIthInfo).forEach((key) => {
          item[key] = fileWIthInfo[key] ?? '';
        });

        const number_of_lines_total = file.additional_info?.number_of_lines_total ?? null;
        const invalid_lines = file.additional_info?.invalid_lines ?? null;
        const valid_lines = number_of_lines_total !== null && invalid_lines !== null
          && number_of_lines_total - invalid_lines;
        const enrichment_with_emails = file?.additional_info?.enrichment_with_emails ?? null;
        const sent_to_enrichment = file?.additional_info?.sent_to_enrichment ?? null;
        const enrichment_rate = enrichment_with_emails !== null && sent_to_enrichment !== null
          && enrichment_with_emails / sent_to_enrichment;
        const has_email = file?.additional_info?.has_email ?? null;
        const lines_without_hem = number_of_lines_total !== null && has_email !== null
            && number_of_lines_total - has_email;
        const lines_match = file?.additional_info?.lines_match ?? null;
        const duplicated = file?.additional_info?.is_duplicate ?? null;
        const match_rate = lines_match !== null && number_of_lines_total !== null
            && lines_match / (number_of_lines_total - duplicated);

        return {
          ...item,
          user: `${file?.user?.first_name} ${file?.user?.last_name}`,
          valid_lines: isNumber(valid_lines) ? valid_lines : '',
          enrichment_rate: isNumber(enrichment_rate) ? enrichment_rate : '',
          lines_without_hem: isNumber(lines_without_hem) ? lines_without_hem : '',
          file_name: file.file_name,
          format: file.format?.format_name ?? '',
          match_rate: isNumber(match_rate) ? match_rate : '',
          number_of_lines_total: item.number_of_lines_total ?? '',
          has_email: item.has_email ?? '',
          advertiser_id: item.advertiser_id ?? '',
          lines_match: item.lines_match ?? '',
          is_duplicate: item.is_duplicate ?? '',
          audience_activation_segment: item?.audience_activation_segment?.name ?? '',
          partner_id: item?.partner?.name ?? '',
          conversion_segment: item?.action_segment?.label ?? '',
          impression_segment: item?.audience_segment?.label ?? '',
          ui_upload_date: moment.utc(item.ui_upload_date).tz(timezone).format(DATE_TIME_FORMAT),
        };
      });

      exportToCsv({ name: 'files.csv', data, keys: EXPORT_KEYS_FILE_TRACKER });
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        if (user?.user_type === USER_TYPE_DATONICS) toastErrorDatonics(errorMessage);
        else toastError(errorMessage);
      }
    }
  },
);

export const offlineDataSlice = createSlice({
  name: 'offlineData',
  reducers: {
    clearOfflineDataReducer: () => initialState,
    setFilename: (state, { payload }) => {
      state.fileName = payload;
    },
    setFileError: (state, { payload }) => {
      state.fileError = payload;
    },
  },
  extraReducers: {
    [downloadFiles.pending]: (state) => {
      state.svgLoading = true;
    },
    [downloadFiles.fulfilled]: (state) => {
      state.svgLoading = false;
    },
    [downloadFiles.rejected]: (state) => {
      state.svgLoading = false;
    },
    [createFormat.pending]: (state) => {
      state.isCreateFormatLoading = true;
    },
    [createFormat.fulfilled]: (state, { payload }) => {
      state.isCreateFormatLoading = false;
      state.newFormat = payload;
    },
    [createFormat.rejected]: (state) => {
      state.isCreateFormatLoading = false;
    },
    [createFormatDataMapping.pending]: (state) => {
      state.isCreateFormatLoading = true;
    },
    [createFormatDataMapping.fulfilled]: (state) => {
      state.isCreateFormatLoading = false;
    },
    [createFormatDataMapping.rejected]: (state) => {
      state.isCreateFormatLoading = false;
    },
    [getFormats.pending]: (state) => {
      state.loading = true;
    },
    [getFormats.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.formats = payload;
    },
    [getFiles.pending]: (state) => {
      state.tableLoading = true;
    },
    [getFiles.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.tableLoading = false;
      state.total = payload?.total || 0;
      state.page = arg.page;
      state.data = payload?.res;
    },
    [uploadFile.rejected]: (state) => {
      state.fileUploadLoading = false;
      state.fileError = true;
    },
    [uploadFile.pending]: (state) => {
      state.fileUploadLoading = true;
    },
    [uploadFile.fulfilled]: (state, { payload }) => {
      state.fileUploadLoading = false;
      state.fileName = payload;
    },
    [getFormatDetails.pending]: (state) => {
      state.loading = true;
    },
    [getFormatDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.formatDetails = payload;
    },
  },
  initialState,
});

export const {
  clearOfflineDataReducer,
  setFilename,
  setFileError,
} = offlineDataSlice.actions;
export default offlineDataSlice.reducer;
